import { COLOR_MODES } from './constants';

export const colors = {
  black: '#00321F',
  lightestGray: '#F7F7F7',
  darkBlue: '#0645AD',
  darkestGray: '#333435',
  darkGray: '#707070',
  darkGreen: '#007448',
  darkPurple: '#0b0080',
  gray: '#A6A6A6',
  green: '#03A007',
  lightestBlue: '#C6DBFF',
  lightBlue: '#A7D7F9',
  lightGray: '#E8E8E8',
  mediumBlue: '#2A82B3',
  mediumDarkGray: '#787878',
  mediumLightBlue: '#84AEEC',
  mediumLightGray: '#D1D1D1',
  transparentBlack: 'rgba(0,0,0,0.3)',
  transparentBlue: 'rgba(209,248,255,0.67)',
  transparentWhite: 'rgba(255,255,255,0.58)',
  white: '#FFFFFF',
};

export const uiColors = {
  dayBackground: colors.lightGray,
  dayBackgroundContrast: colors.lightestGray,
  dayText: colors.gray,
  dayTextActive: colors.black,
  dayTextDisabled: colors.mediumLightGray,
  dayTextHover: colors.darkestGray,
  nightBackground: '#323639',
  nightBackgroundContrast: '#282C2F',
  nightText: colors.gray,
  nightTextActive: colors.white,
  nightTextDisabled: colors.darkGray,
  nightTextHover: '#F0F1F2',
};

export const paletteColors = [
  { name: 'Light Pink', hex: '#F5C0D5', code: 'P79' },
  { name: 'Bubble Gum', hex: '#E16D9D', code: 'P06' },
  { name: 'Pink', hex: '#E65794', code: 'P83' },
  { name: 'Magenta', hex: '#F34676', code: 'P38' },
  { name: 'Raspberry', hex: '#AD3C6C', code: 'P88' },
  { name: 'Peach', hex: '#FCC6B8', code: 'P33' },
  { name: 'Flamingo', hex: '#FFB5BE', code: 'P204' },
  { name: 'Sand', hex: '#EAC49F', code: 'P98' },
  { name: 'Blush', hex: '#FF9E8D', code: 'P63' },
  { name: 'Salmon', hex: '#FF777F', code: 'P203' },
  { name: 'Hot Coral', hex: '#FF6158', code: 'P59' },
  { name: 'Tomato', hex: '#EA4241', code: 'P211' },
  { name: 'Red', hex: '#C43A44', code: 'P05' },
  { name: 'Cherry', hex: '#AD3345', code: 'P961' },
  { name: 'Cranapple', hex: '#88404F', code: 'P96' },
  { name: 'Cheddar', hex: '#FFB64E', code: 'P57' },
  { name: 'Apricot', hex: '#FFA967', code: 'P213' },
  { name: 'Butterscotch', hex: '#E19A52', code: 'P90' },
  { name: 'Spice', hex: '#E35C44', code: 'P212' },
  { name: 'Orange', hex: '#FF803E', code: 'P04' },
  { name: 'Rust', hex: '#A04E3F', code: 'P20' },
  { name: 'Crème', hex: '#EDE7BA', code: 'P02' },
  { name: 'Pastel Yellow', hex: '#FAEE8D', code: 'P56' },
  { name: 'Yellow', hex: '#F9D737', code: 'P03' },
  // { name: 'Glow In The Dark Green', hex: '#AFC39D', code: 'P75' },
  { name: 'Sherbert', hex: '#E1EE7D', code: 'P214' },
  { name: 'Prickly Pear', hex: '#CBD735', code: 'P97' },
  { name: 'Fern', hex: '#7B9730', code: 'P219' },
  { name: 'Olive', hex: '#72763E', code: 'P220' },
  { name: 'Kiwi Lime', hex: '#77CA4A', code: 'P61' },
  { name: 'Pastel Green', hex: '#73D594', code: 'P53' },
  { name: 'Green', hex: '#54B160', code: 'P80' },
  { name: 'Shamrock', hex: '#009654', code: 'P99' },
  { name: 'Dark Green', hex: '#108355', code: 'P10' },
  { name: 'Evergreen', hex: '#3C614F', code: 'P179' },
  { name: "Robin's Egg", hex: '#B4D9DF', code: 'P202' },
  { name: 'Toothpaste', hex: '#B0E8D5', code: 'P58' },
  { name: 'Sky', hex: '#54CDE3', code: 'P216' },
  { name: 'Light Green', hex: '#38C7AF', code: 'P11' },
  { name: 'Lagoon', hex: '#00ABB2', code: 'P217' },
  { name: 'Parrot Green', hex: '#00968A', code: 'P91' },
  { name: 'Teal', hex: '#368D97', code: 'P218' },
  { name: 'Clear Blue', hex: '#7CD2F2', code: '184' },
  { name: 'Pastel Blue', hex: '#63A9D6', code: 'P52' },
  { name: 'Turquoise', hex: '#008FCC', code: 'P62' },
  { name: 'Light Blue', hex: '#278ACB', code: 'P09' },
  { name: 'Cobalt', hex: '#0066B3', code: 'P200' },
  { name: 'Dark Blue', hex: '#2B307C', code: 'P08' },
  { name: 'Midnight', hex: '#162846', code: 'P201' },
  { name: 'Blueberry Crème', hex: '#87A7E1', code: 'P93' },
  { name: 'Periwinkle Blue', hex: '#6C88BF', code: 'P70' },
  { name: 'Lavender', hex: '#B4A6D3', code: 'P82' },
  { name: 'Pastel Lavender', hex: '#9582BB', code: 'P54' },
  { name: 'Purple', hex: '#6F5493', code: 'P07' },
  { name: 'Orchid', hex: '#B56C99', code: 'P210' },
  { name: 'Plum', hex: '#B25FAA', code: 'P60' },
  { name: 'Toasted Marshmallow', hex: '#F1E5D8', code: 'P208' },
  { name: 'Fawn', hex: '#D7B087', code: 'P205' },
  { name: 'Tan', hex: '#CFA889', code: 'P35' },
  { name: 'Light Brown', hex: '#9D7153', code: 'P21' },
  { name: 'Gold Metallic', hex: '#B57F45', code: 'P85' },
  { name: 'Brown', hex: '#6C524D', code: 'P12' },
  { name: 'Clear', hex: '#9EA7AE', code: 'P19' },
  { name: 'Light Grey', hex: '#BEC3BF', code: 'P81' },
  { name: 'Grey', hex: '#96989C', code: 'P17' },
  { name: 'Dark Grey', hex: '#56575C', code: 'P92' },
  { name: 'Mist', hex: '#9CB9C7', code: 'P215' },
  { name: 'Pewter', hex: '#93A19F', code: 'P206' },
  { name: 'Charcoal', hex: '#545F5F', code: 'P207' },
  { name: 'Black', hex: '#000000', code: 'P18' },
  { name: 'White', hex: '#FFFFFF', code: 'P01' },
  // { name: 'Neon Green', hex: '#00AC4A' },
  // { name: 'Neon Yellow', hex: '#BDD200' },
  // { name: 'Neon Pink', hex: '#F15289' },
  // { name: 'Neon Orange', hex: '#FF8D2E' },
  // { name: 'Neon Blue', hex: '#2255B7' },
  // { name: 'Silver', hex: '#777B81' },
  // { name: 'Copper', hex: '#97634E' },
  // { name: 'Transparent Turquoise', hex: '#006D8B' },
  // { name: 'Pearl', hex: '#D8D6C7' },
  // { name: 'Pearl Pink', hex: '#E37BAD' },
  // { name: 'Pearl Light Pink', hex: '#CCA49B' },
  // { name: 'Pearl Coral', hex: '#E99591' },
  // { name: 'Pearl Yellow', hex: '#D1C66E' },
  // { name: 'Pearl Green', hex: '#84B095' },
  // { name: 'Pearl Light Blue', hex: '#7AAEA2' },
  // { name: 'Red Glitter', hex: '#9D382E' },
  // { name: 'Blue Glitter', hex: '#0069C0' },
  // { name: 'Purple Glitter', hex: '#694184' },
  // { name: 'Yellow Glitter', hex: '#BAB456' },
  // { name: 'Green Glitter', hex: '#00876E' },
  // { name: 'Clear Glitter', hex: '#A6A9B1' },
  // { name: 'Black Glitter', hex: '#383737' },
  // { name: 'White Glitter', hex: '#D0DBDE' },
  // { name: 'Pink Glitter', hex: '#E2587F' },
  // { name: 'Fairy Dust', hex: '#9BAF9E' },
  // { name: 'Kiwi Glitter', hex: '#559E45' },
  // { name: 'Glow Blue', hex: '#56ACBE' },
  // { name: 'Glow Pink', hex: '#EE75A8' },
  // { name: 'Glow Purple', hex: '#AE66B1' },
  // { name: 'Glow Orange', hex: '#EAAF6F' },
  // { name: 'Color Change Blue', hex: '#649DCF' },
  // { name: 'Color Change Purple', hex: '#9D74C4' },
  // { name: 'Color Change Pink', hex: '#CD699E' },
  // { name: 'Color Change Red', hex: '#C86069' },
  // { name: 'Color Change Green', hex: '#55AF7E' },
  // { name: 'Color Change Dark Green', hex: '#384E48' },
  // { name: 'Color Change Black', hex: '#494D4D' },
];

export const paletteHexStrings = paletteColors.map(color => color.hex);

const modeTileColors = {};
Object.keys(COLOR_MODES).forEach(mode => {
  modeTileColors[mode] = [
    `${uiColors[`${mode}Background`]}`,
    `${uiColors[`${mode}BackgroundContrast`]}`,
  ];
});
export const tileColors = modeTileColors;
